import { useRouter } from "next/router";

import syncICL from "sync/icl.json";
import syncEVO from "sync/evo.json";

import { LANGUAGE_MAP } from "shared";
import { SiteInfo, SiteJsonNormalized } from "../settings-types";

export const useSiteInfo = () => {
  const router = useRouter();
  const locale: string = router.locale ?? "";

  const siteInfo = getSiteInfo(locale);
  const isUS = siteInfo?.iso === LANGUAGE_MAP.US;
  const isCA = siteInfo?.iso === LANGUAGE_MAP.CA;
  const isFC = siteInfo?.iso === LANGUAGE_MAP.FC;
  const isIN = siteInfo?.iso === LANGUAGE_MAP.IN;
  const isKR = siteInfo?.iso === LANGUAGE_MAP.KR;
  const isJP = siteInfo?.iso === LANGUAGE_MAP.JP;
  const isPL = siteInfo?.iso === LANGUAGE_MAP.PL;
  const isNL = siteInfo?.iso === LANGUAGE_MAP.NL;
  const isES = siteInfo?.iso === LANGUAGE_MAP.ES;
  const isGE = siteInfo?.iso === LANGUAGE_MAP.GE;
  const isLA = siteInfo?.iso === LANGUAGE_MAP.LA;

  return {
    ...siteInfo,
    isUS,
    isIN,
    isCA,
    isFC,
    isKR,
    isJP,
    isPL,
    isNL,
    isES,
    isGE,
    isLA,
  };
};

export const getSiteInfo = (locale: string): SiteInfo => {
  let site: SiteJsonNormalized = null;

  if (/^.+-evo$/.test(locale)) {
    // evo locale
    site = Object.values(syncEVO.sites).find((item) => {
      return item.locale === locale.replace("-evo", "");
    });
    if (!site) {
      // fallback to de-De
      site = syncEVO.sites["de-DE"];
    }
  } else {
    // icl locale
    site = Object.values(syncICL.sites).find((item) => {
      return item.locale === locale.replace("-iframe", "");
    });
    if (!site) {
      // fallback to en-US
      site = syncICL.sites["en-US"];
    }
  }

  return {
    name: site.name ?? "",
    layout: site.layout,
    iso: site.locale,
    code: site.locale,
    dir: site.rtl ? "rtl" : "ltr",
    file: site.locale + "/common.json",
    handle: site.handle,
    iframe: site.hostUrl === site.iframeUrl || /^.+-iframe$/.test(locale),
    branded: site.branded ?? "",
    discoverUrl: site.baseUrl,
    doctorUrl: site.hostUrl,
    quizUrl: site.quizUrl ?? "",
  };
};
